import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ReactMarkdown from 'react-markdown';
import LanguageSwitcher from '../LanguageSwitcher';

const CookiesConsent = ({ cookiesContent, translation, NodeLocales, currentNodeLocale }) => {
  const [cookies, setCookie] = useCookies([]);
  const [showCookies, setShowCookies] = useState(true);
  const [selectedCookies, setSelectedCookies] = useState([]);

  const currentDate = new Date();
  const cookiesExpirationDate = new Date(currentDate.setDate(currentDate.getDate() + 30));

  useEffect(() => {
    if (showCookies) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = null;
      document.body.style.height = null;
    }
    Object.keys(cookies).length > 0 && setShowCookies(false);
  }, [cookies, showCookies]);

  const acceptAllCookies = () => {
    cookiesContent.essentialSubCategories.map((cookie) => {
      setCookie(cookie.cookieSubCategory, cookie.cookieValue, { path: '/', expires: cookiesExpirationDate });
    });

    cookiesContent.optionalSubCategories.map((cookie) => {
      setCookie(cookie.cookieSubCategory, cookie.cookieValue, { path: '/', expires: cookiesExpirationDate });
    });

    setShowCookies(false);
  };

  const acceptSelectedCookies = () => {
    selectedCookies.length > 0
      ? cookiesContent.optionalSubCategories.map((cookie) => {
          if (selectedCookies.includes(cookie.cookieSubCategory))
            setCookie(cookie.cookieSubCategory, cookie.cookieValue, { path: '/', expires: cookiesExpirationDate });
        })
      : cookiesContent.essentialSubCategories.map((cookie) => {
          setCookie(cookie.cookieSubCategory, cookie.cookieValue, { path: '/', expires: cookiesExpirationDate });
        });
    setShowCookies(false);
  };

  const handleChange = (e) => {
    const cookieChecked = e.target.name;
    const index = selectedCookies.indexOf(cookieChecked);
    const cookieSubCategorySelections =
      index === -1
        ? [...selectedCookies, cookieChecked]
        : [...selectedCookies.slice(0, index), ...selectedCookies.slice(index + 1)];

    setSelectedCookies(cookieSubCategorySelections);
  };

  return (
    <div className={!showCookies ? 'hidden' : 'modal modal--delayed modal--cookies'}>
      <div className="modal__content ">
        <div className="mb-2 md:mb-4 w-5/12 md:w-1/3">
          <LanguageSwitcher NodeLocales={NodeLocales} currentNodeLocale={currentNodeLocale} translation={translation} />
        </div>
        <div className="modal__context mb-4">{documentToReactComponents(cookiesContent.content.json)}</div>
        <div className="form">
          {cookiesContent.essentialSubCategories.map((subCategory, index) => {
            return (
              <div className="form__group form__group--primary form__group--cookiesForm" key={index}>
                <input
                  className="form__input form__input--checkbox"
                  type="checkbox"
                  id={`id${subCategory.cookieSubCategory}`}
                  checked
                />
                <label className="form__label" htmlFor={`id${subCategory.cookieSubCategory}`}>
                  <span className="form__label-checkbox form__label--essential"></span>
                  <span className="form__label-text">{subCategory.cookieSubCategory}</span>
                </label>
                <div>
                  <ReactMarkdown
                    source={subCategory.subCategoryDescription.childMarkdownRemark.rawMarkdownBody}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed={true}
                  />
                </div>
              </div>
            );
          })}
          {cookiesContent.optionalSubCategories.map((subCategory, index) => {
            return (
              <div className="form__group form__group--primary form__group--cookiesForm" key={index}>
                <input
                  className="form__input form__input--checkbox"
                  type="checkbox"
                  name={subCategory.cookieSubCategory}
                  id={`id${subCategory.cookieSubCategory}`}
                  onChange={(e) => handleChange(e)}
                />

                <label className="form__label" htmlFor={`id${subCategory.cookieSubCategory}`}>
                  <span className="form__label-checkbox"></span>
                  <span className="form__label-text">{subCategory.cookieSubCategory}</span>
                </label>

                <div>
                  <ReactMarkdown
                    source={subCategory.subCategoryDescription.childMarkdownRemark.rawMarkdownBody}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed={true}
                  />
                </div>
              </div>
            );
          })}

          <div className="flex justify-around mt-base">
            <button
              className="button button--primary button--outline button--sm mr-4 md:mr-0"
              onClick={() => acceptSelectedCookies()}
            >
              {cookiesContent.buttons[0].linkName}
            </button>
            <button className="button button--primary button--outline button--sm" onClick={() => acceptAllCookies()}>
              {cookiesContent.buttons[1].linkName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesConsent;
