import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';
import Header from './Header';
import CookieConsent from './CookieConsent/CookiesConsent';
import Footer from './Footer/Footer';
import '../stylesheets/main.scss';

const Layout = (props) => {
  const {
    children,
    headerContent,
    footerContent,
    currentLocation,
    themeColor,
    currentNodeLocale,
    translation,
    excludeComponents,
    excludeFooter,
  } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          heading: allSite {
            edges {
              node {
                siteMetadata {
                  description
                  title
                  locales
                }
              }
            }
          }
        }
      `}
      render={({ heading }) => (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <body className={themeColor} />
            <script
              defer
              src="https://static.cloudflareinsights.com/beacon.min.js"
              data-cf-beacon='{"token": "c5fb5cfa065a4128a67d0c3eeb027915"}'
            ></script>
          </Helmet>
          <CookiesProvider>
            {!excludeComponents && (
              <Header
                translation={translation}
                currentLocation={currentLocation}
                headerContent={headerContent}
                currentNodeLocale={currentNodeLocale}
                NodeLocales={heading.edges[0].node.siteMetadata.locales}
              />
            )}

            {/*  on header activeDocMeta={activeDocMeta}  */}
            <main>{children}</main>
            {!excludeComponents && (
              <CookieConsent
                cookiesContent={headerContent.cookies[0]}
                translation={translation}
                NodeLocales={heading.edges[0].node.siteMetadata.locales}
                currentNodeLocale={currentNodeLocale}
              />
            )}
            {!excludeComponents && !excludeFooter && (
              <Footer
                footerContent={footerContent}
                currentLocation={currentLocation}
                translation={translation}
                currentNodeLocale={currentNodeLocale}
                NodeLocales={heading.edges[0].node.siteMetadata.locales}
              />
            )}
          </CookiesProvider>
        </>
      )}
    />
  );
};

export default Layout;
