import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const FooterSocial = ({ footerSocialContent, footerSocialLinks, footerCopyrightContent, footerMadeWithContent }) => {
  return (
    <div className="footer__social section section--slim">
      <div className="container ">
        {footerCopyrightContent}
        <div className="footer__social-icons">
          {footerSocialContent.map((socialIcon, index) => {
            return (
              <a href={footerSocialLinks[index]} target="_blank" key={index}>
                <img src={socialIcon.file.url} alt={socialIcon.description ? socialIcon.description : ''} />
              </a>
            );
          })}
        </div>
        <div dangerouslySetInnerHTML={{ __html: footerMadeWithContent }} />
      </div>
    </div>
  );
};

export default FooterSocial;
