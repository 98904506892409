import React from 'react';

const Chevron = (props) => {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke={props.fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export default Chevron;
