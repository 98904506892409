import React from 'react';
import { Link } from 'gatsby';
const CustomLink = (props) => {
  const { link } = props;
  return (
    <>
      {!link.enableLink ? (
        <> </>
      ) : link.externalLink ? (
        <a
          href={link.link}
          target="_blank"
          className={!props.customClass ? `${link.extraClasses ? link.extraClasses : 'more'}` : `${props.customClass}`}
        >
          {link.linkName}
        </a>
      ) : (
        <Link
          to={link.link ? link.link : '#'}
          className={!props.customClass ? `${link.extraClasses ? link.extraClasses : 'more'}` : `${props.customClass}`}
        >
          {link.linkName}
        </Link>
      )}
    </>
  );
};
export default CustomLink;
