import React from 'react';
import Select from 'react-select';
import { navigate } from 'gatsby';

const LanguageSwitcher = (props) => {
  const { NodeLocales, currentNodeLocale, translation } = props;
  const currentLang = currentNodeLocale.toUpperCase();
  const options = [];

  NodeLocales.map((locale) => {
    let option = {
      value: locale,
      label: locale,
    };
    options.push(option);
  });

  const handleLangChange = () => {
    if (translation) {
      navigate(translation);
    } else {
      currentNodeLocale === 'en' ? navigate('/') : navigate('/en');
    }
  };

  return (
    <Select value={currentLang === 'DE' ? options[0] : options[1]} onChange={handleLangChange} options={options} />
  );
};

export default LanguageSwitcher;
