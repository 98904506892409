import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import LanguageSwitcher from './LanguageSwitcher';
import { INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Header = ({ currentLocation, headerContent, currentNodeLocale, NodeLocales, translation }) => {
  const [menuOpenCloseStatus, setMenuOpenCloseStatus] = useState(false);
  useEffect(() => {
    if (menuOpenCloseStatus) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = null;
      document.body.style.height = null;
    }
  }, [menuOpenCloseStatus]);

  const renderedMenuLinks = headerContent.navigation.map((header, index) => (
    <li key={header + index}>
      {header.externalLink ? (
        <a
          href={header.link}
          target="_blank"
          className={`header__nav-list-item ${header.extraClasses ? header.extraClasses : ''}`}
        >
          {header.linkName}
        </a>
      ) : (
        <Link
          to={header.link}
          className={`header__nav-list-item ${header.extraClasses ? header.extraClasses : ''} ${
            currentLocation.includes(header.link) ? 'plan-list__item--active' : ' '
          }`}
        >
          {header.linkName}
        </Link>
      )}
      {header.enableLink === false && <span className="badge badge--neutral mb-1 ml-1">{header.badge}</span>}
    </li>
  ));

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, next) => {
        return `<a href="${node.data.uri}"' target="_blank"'
      }>${next(node.content)}</a>`;
      },
    },
  };

  return (
    <>
      <div className={`notification-bar ${headerContent.enableNotificationBar ? '' : 'hidden'}`}>
        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(headerContent.notificationBar.json, options),
          }}
        />
      </div>
      <header className="header">
        <div className="container header__container ">
          <a className="header__logo " href="/">
            <img
              src={headerContent.logo.file.url}
              alt={headerContent.logo.description ? headerContent.logo.description : ''}
            />
          </a>

          {headerContent.helpline[0].enableLink &&
            headerContent.helpline.map((helplineItem, index) => {
              return (
                <div className="header__actions">
                  <Link
                    key={index}
                    to={helplineItem.link}
                    href="#"
                    className="button button--primary button--outline button--sm lg:hidden"
                  >
                    {helplineItem.linkName}
                  </Link>
                </div>
              );
            })}

          <button
            onClick={() => setMenuOpenCloseStatus(!menuOpenCloseStatus)}
            className={`header__toggle${menuOpenCloseStatus ? ' header__toggle--active' : ''}`}
          >
            <span className="header__toggle-icon"></span>
            <span className="header__toggle-icon"></span>
            <span className="header__toggle-icon"></span>
          </button>

          <nav className="header__nav">
            <ul className="header__nav-list plan-list">
              <li>
                <Link
                  className={
                    !currentLocation.includes('business')
                      ? `header__nav-list-item plan-list__item  plan-list__item--active ${
                          headerContent.menuPlans[0].extraClasses ? headerContent.menuPlans[0].extraClasses : ''
                        } `
                      : `header__nav-list-item plan-list__item  ${
                          headerContent.menuPlans[0].extraClasses ? headerContent.menuPlans[0].extraClasses : ''
                        } `
                  }
                  to={headerContent.menuPlans[0].link}
                  href="#"
                >
                  {headerContent.menuPlans[0].linkName}
                </Link>
              </li>
              <li>
                <Link
                  className={
                    currentLocation.includes('business')
                      ? `header__nav-list-item plan-list__item  plan-list__item--active ${
                          headerContent.menuPlans[1].extraClasses ? headerContent.menuPlans[0].extraClasses : ' '
                        }`
                      : `header__nav-list-item ${
                          headerContent.menuPlans[1].extraClasses ? headerContent.menuPlans[0].extraClasses : ' '
                        }`
                  }
                  to={headerContent.menuPlans[1].link}
                  href="#"
                >
                  {headerContent.menuPlans[1].linkName}
                  {headerContent.menuPlans[1].enableLink === false && (
                    <span className="badge badge--neutral -mt-2 ml-1">{headerContent.menuPlans[1].badge}</span>
                  )}
                </Link>
              </li>
            </ul>
            <ul className="header__nav-list">
              {renderedMenuLinks}
              <li
                className={`header__nav-list--lanswitcher text-teal-200 hover:text-white ${
                  menuOpenCloseStatus ? 'block' : 'hidden'
                }`}
              >
                <LanguageSwitcher
                  NodeLocales={NodeLocales}
                  currentNodeLocale={currentNodeLocale}
                  translation={translation}
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
