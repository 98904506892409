import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Accordion from '../Accordion/Accordion';
import FooterSocial from './FooterSocial';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LanguageSwitcher from '../LanguageSwitcher';
import CustomLink from '../Link';

const Footer = ({ footerContent, currentLocation, translation, currentNodeLocale, NodeLocales }) => {
  const firstColumnAccordionItems = [];
  const SecondColumnAccordionItems = [];

  footerContent.accordion.map((accordionItem) => {
    accordionItem.columnPlacement === 1
      ? firstColumnAccordionItems.push(accordionItem)
      : SecondColumnAccordionItems.push(accordionItem);
  });
  const options = {
    renderNode: {
      'embedded-asset-block': (node) => {
        const alt = node.data.target.fields.title[currentNodeLocale];
        const url = node.data.target.fields.file[currentNodeLocale].url;
        return <img src={url} alt={alt} />;
      },
    },
  };

  useEffect(() => {
    var scrollToTopBtn = document.querySelector('.scrollToTopBtn');
    var rootElement = document.documentElement;

    function handleScroll() {
      // Do something on scroll
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.2) {
        // Show button
        scrollToTopBtn.classList.add('showBtn');
      } else {
        // Hide button
        scrollToTopBtn.classList.remove('showBtn');
      }
    }

    function scrollToTop() {
      // Scroll to top logic
      rootElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    scrollToTopBtn.addEventListener('click', scrollToTop);
    document.addEventListener('scroll', handleScroll);
  }, []);

  // const widgetClickHandler = (e) => {
  //   e.preventDefault();
  //   let element = document.getElementsByClassName('anonymous_chat_pop_up_iframe');

  //   element[0].classList.remove('close');
  //   element[0].classList.toggle('open');

  //   if (!element[0].classList.contains('opened')) {
  //     document.getElementById('iframeHolder').innerHTML =
  //       '<iframe src="https://app.gaius.legal/anonymous" name="anonymous_chat_pop_up_iframe" id="1" width="375" height="600" frameborder="0" marginwidth="0" marginheight="0" scrolling="auto" onload="" allowtransparency="false" allow="camera;microphone" ></iframe>';
  //   }
  //   element[0].classList.add('opened');
  // };

  return (
    <>
      <div className="cta">
        {footerContent.mobileFloatingMenu.map((floatingLink, index) => {
          return (
            <div key={index} className="cta__button">
              <CustomLink link={floatingLink} />
            </div>
          );
        })}
      </div>
      <footer className="footer">
        <div className="footer__content section">
          <div className="container ">
            <div className="footer__content-cols">
              <div className="footer__column">
                <ul className="plan-list plan-list--inline">
                  <li className="xl:pr-md">
                    <Link
                      className={
                        !currentLocation.includes('business')
                          ? 'plan-list__item plan-list__item--active'
                          : 'plan-list__item '
                      }
                      to={footerContent.menuPlans[0].link}
                      href="#responsive-footer"
                    >
                      {footerContent.menuPlans[0].linkName}
                    </Link>
                  </li>
                  <li>
                    <span
                      className={
                        currentLocation.includes('business')
                          ? 'plan-list__item plan-list__item--active'
                          : 'plan-list__item '
                      }
                    >
                      {footerContent.menuPlans[1].linkName}
                      {footerContent.menuPlans[1].available === false && (
                        <span className="badge badge--neutral absolute -mt-2 ml-1">
                          {footerContent.menuPlans[1].badge}
                        </span>
                      )}
                    </span>
                  </li>
                </ul>
                <div className="footer__widget footer__widget--images">
                  {documentToReactComponents(footerContent.footerGallery.json, options)}
                </div>
                <div className=" footer__lan-select mb-md">
                  <LanguageSwitcher
                    NodeLocales={NodeLocales}
                    currentNodeLocale={currentNodeLocale}
                    translation={translation}
                  />
                </div>
              </div>
              <div className="footer__column">
                {firstColumnAccordionItems.map((accordionContent) => {
                  return <Accordion accordionContent={accordionContent} />;
                })}
              </div>
              <div className="footer__column">
                {SecondColumnAccordionItems.map((accordionContent) => {
                  return <Accordion accordionContent={accordionContent} />;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="footer__disclaimer section section--slim">
          <div className="container">{documentToReactComponents(footerContent.disclaimer.json)}</div>
        </div>

        <FooterSocial
          footerSocialContent={footerContent.social}
          footerSocialLinks={footerContent.socialLinks}
          footerCopyrightContent={footerContent.copyright}
          footerMadeWithContent={footerContent.madeWithLove}
        />
        {/* Chat Widget */}
        {/* <div className="anonymous_chat_pop_up_iframe close" id="iframeHolder"></div>
        <a
          href="#"
          className="anonymous_chat_pop_up_link link-desktop"
          onClick={(e) => widgetClickHandler(e)}
          point="https://app.gaius.legal/anonymous"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="32"
            height="32"
            viewBox="0 0 79 60"
            version="1.1"
            className="svg replaced-svg"
          >
            <defs></defs>
            <g id="Hotline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-907.000000, -3627.000000)" id="Group-23" fill="#ffffff">
                <g transform="translate(101.000000, 3479.000000)">
                  <g id="Comments" transform="translate(806.000000, 148.000000)">
                    <path
                      d="M1.30434783,43.0434783 C1.11652174,43.0434783 0.927391304,43.0030435 0.751304348,42.9195652 C0.293478261,42.7056522 0,42.2452174 0,41.7391304 L0,7.82478261 C0,3.51 3.50869565,0 7.82347826,0 L39.1317391,0 C43.4465217,0 46.9565217,3.51391304 46.9565217,7.83391304 L46.9565217,14.3778261 C46.9565217,15.0991304 46.3734783,15.6821739 45.6521739,15.6821739 C44.9308696,15.6821739 44.3478261,15.0991304 44.3478261,14.3778261 L44.3478261,7.83391304 C44.3478261,4.9526087 42.0078261,2.60869565 39.1317391,2.60869565 L7.82347826,2.60869565 C4.94869565,2.60869565 2.60869565,4.94869565 2.60869565,7.82478261 L2.60869565,38.9543478 L9.86086957,32.91 C10.0943478,32.7156522 10.3904348,32.6086957 10.6956522,32.6086957 L28.8913043,32.6086957 C29.6126087,32.6086957 30.1956522,33.1917391 30.1956522,33.9130435 C30.1956522,34.6343478 29.6126087,35.2173913 28.8913043,35.2173913 L11.1678261,35.2173913 L2.14043478,42.7421739 C1.90043478,42.9404348 1.60304348,43.0434783 1.30434783,43.0434783"
                      id="Fill-975"
                    ></path>
                    <path
                      d="M32.6086957,60 C32.4208696,60 32.2317391,59.9595652 32.0556522,59.876087 C31.5978261,59.6621739 31.3043478,59.2017391 31.3043478,58.6956522 L31.3043478,24.7813043 C31.3043478,20.4665217 34.8130435,16.9565217 39.1278261,16.9565217 L70.436087,16.9565217 C74.7508696,16.9565217 78.2608696,20.4665217 78.2608696,24.7813043 L78.2608696,44.3491304 C78.2608696,48.663913 74.7508696,52.173913 70.436087,52.173913 L42.4721739,52.173913 L33.4447826,59.6986957 C33.2047826,59.8969565 32.9073913,60 32.6086957,60 L32.6086957,60 Z M39.1278261,19.5652174 C36.2530435,19.5652174 33.9130435,21.9052174 33.9130435,24.7813043 L33.9130435,55.9108696 L41.1652174,49.8665217 C41.3986957,49.6721739 41.6947826,49.5652174 42,49.5652174 L70.436087,49.5652174 C73.3121739,49.5652174 75.6521739,47.2252174 75.6521739,44.3491304 L75.6521739,24.7813043 C75.6521739,21.9052174 73.3121739,19.5652174 70.436087,19.5652174 L39.1278261,19.5652174 L39.1278261,19.5652174 Z"
                      id="Fill-976"
                    ></path>
                    <path
                      d="M41.7391304,39.1304348 C39.5817391,39.1304348 37.826087,37.3747826 37.826087,35.2173913 C37.826087,33.06 39.5817391,31.3043478 41.7391304,31.3043478 C43.8965217,31.3043478 45.6521739,33.06 45.6521739,35.2173913 C45.6521739,37.3747826 43.8965217,39.1304348 41.7391304,39.1304348 L41.7391304,39.1304348 Z M41.7391304,33.9130435 C41.0191304,33.9130435 40.4347826,34.4973913 40.4347826,35.2173913 C40.4347826,35.9373913 41.0191304,36.5217391 41.7391304,36.5217391 C42.4591304,36.5217391 43.0434783,35.9373913 43.0434783,35.2173913 C43.0434783,34.4973913 42.4591304,33.9130435 41.7391304,33.9130435 L41.7391304,33.9130435 Z"
                      id="Fill-977"
                    ></path>
                    <path
                      d="M54.7826087,39.1304348 C52.6252174,39.1304348 50.8695652,37.3747826 50.8695652,35.2173913 C50.8695652,33.06 52.6252174,31.3043478 54.7826087,31.3043478 C56.94,31.3043478 58.6956522,33.06 58.6956522,35.2173913 C58.6956522,37.3747826 56.94,39.1304348 54.7826087,39.1304348 L54.7826087,39.1304348 Z M54.7826087,33.9130435 C54.0626087,33.9130435 53.4782609,34.4973913 53.4782609,35.2173913 C53.4782609,35.9373913 54.0626087,36.5217391 54.7826087,36.5217391 C55.5026087,36.5217391 56.0869565,35.9373913 56.0869565,35.2173913 C56.0869565,34.4973913 55.5026087,33.9130435 54.7826087,33.9130435 L54.7826087,33.9130435 Z"
                      id="Fill-978"
                    ></path>
                    <path
                      d="M67.826087,39.1304348 C65.6686957,39.1304348 63.9130435,37.3747826 63.9130435,35.2173913 C63.9130435,33.06 65.6686957,31.3043478 67.826087,31.3043478 C69.9834783,31.3043478 71.7391304,33.06 71.7391304,35.2173913 C71.7391304,37.3747826 69.9834783,39.1304348 67.826087,39.1304348 L67.826087,39.1304348 Z M67.826087,33.9130435 C67.106087,33.9130435 66.5217391,34.4973913 66.5217391,35.2173913 C66.5217391,35.9373913 67.106087,36.5217391 67.826087,36.5217391 C68.546087,36.5217391 69.1304348,35.9373913 69.1304348,35.2173913 C69.1304348,34.4973913 68.546087,33.9130435 67.826087,33.9130435 L67.826087,33.9130435 Z"
                      id="Fill-979"
                    ></path>
                    <path
                      d="M10.4347826,22.173913 C8.2773913,22.173913 6.52173913,20.4182609 6.52173913,18.2608696 C6.52173913,16.1034783 8.2773913,14.3478261 10.4347826,14.3478261 C12.5921739,14.3478261 14.3478261,16.1034783 14.3478261,18.2608696 C14.3478261,20.4182609 12.5921739,22.173913 10.4347826,22.173913 L10.4347826,22.173913 Z M10.4347826,16.9565217 C9.71478261,16.9565217 9.13043478,17.5408696 9.13043478,18.2608696 C9.13043478,18.9808696 9.71478261,19.5652174 10.4347826,19.5652174 C11.1547826,19.5652174 11.7391304,18.9808696 11.7391304,18.2608696 C11.7391304,17.5408696 11.1547826,16.9565217 10.4347826,16.9565217 L10.4347826,16.9565217 Z"
                      id="Fill-980"
                    ></path>
                    <path
                      d="M23.4782609,22.173913 C21.3208696,22.173913 19.5652174,20.4182609 19.5652174,18.2608696 C19.5652174,16.1034783 21.3208696,14.3478261 23.4782609,14.3478261 C25.6356522,14.3478261 27.3913043,16.1034783 27.3913043,18.2608696 C27.3913043,20.4182609 25.6356522,22.173913 23.4782609,22.173913 L23.4782609,22.173913 Z M23.4782609,16.9565217 C22.7582609,16.9565217 22.173913,17.5408696 22.173913,18.2608696 C22.173913,18.9808696 22.7582609,19.5652174 23.4782609,19.5652174 C24.1982609,19.5652174 24.7826087,18.9808696 24.7826087,18.2608696 C24.7826087,17.5408696 24.1982609,16.9565217 23.4782609,16.9565217 L23.4782609,16.9565217 Z"
                      id="Fill-981"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <a href="https://app.gaius.legal/anonymous" className="anonymous_chat_pop_up_link link-mobile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="32"
            height="32"
            viewBox="0 0 79 60"
            version="1.1"
            className="svg replaced-svg"
          >
            <defs></defs>
            <g id="Hotline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-907.000000, -3627.000000)" id="Group-23" fill="#ffffff">
                <g transform="translate(101.000000, 3479.000000)">
                  <g id="Comments" transform="translate(806.000000, 148.000000)">
                    <path
                      d="M1.30434783,43.0434783 C1.11652174,43.0434783 0.927391304,43.0030435 0.751304348,42.9195652 C0.293478261,42.7056522 0,42.2452174 0,41.7391304 L0,7.82478261 C0,3.51 3.50869565,0 7.82347826,0 L39.1317391,0 C43.4465217,0 46.9565217,3.51391304 46.9565217,7.83391304 L46.9565217,14.3778261 C46.9565217,15.0991304 46.3734783,15.6821739 45.6521739,15.6821739 C44.9308696,15.6821739 44.3478261,15.0991304 44.3478261,14.3778261 L44.3478261,7.83391304 C44.3478261,4.9526087 42.0078261,2.60869565 39.1317391,2.60869565 L7.82347826,2.60869565 C4.94869565,2.60869565 2.60869565,4.94869565 2.60869565,7.82478261 L2.60869565,38.9543478 L9.86086957,32.91 C10.0943478,32.7156522 10.3904348,32.6086957 10.6956522,32.6086957 L28.8913043,32.6086957 C29.6126087,32.6086957 30.1956522,33.1917391 30.1956522,33.9130435 C30.1956522,34.6343478 29.6126087,35.2173913 28.8913043,35.2173913 L11.1678261,35.2173913 L2.14043478,42.7421739 C1.90043478,42.9404348 1.60304348,43.0434783 1.30434783,43.0434783"
                      id="Fill-975"
                    ></path>
                    <path
                      d="M32.6086957,60 C32.4208696,60 32.2317391,59.9595652 32.0556522,59.876087 C31.5978261,59.6621739 31.3043478,59.2017391 31.3043478,58.6956522 L31.3043478,24.7813043 C31.3043478,20.4665217 34.8130435,16.9565217 39.1278261,16.9565217 L70.436087,16.9565217 C74.7508696,16.9565217 78.2608696,20.4665217 78.2608696,24.7813043 L78.2608696,44.3491304 C78.2608696,48.663913 74.7508696,52.173913 70.436087,52.173913 L42.4721739,52.173913 L33.4447826,59.6986957 C33.2047826,59.8969565 32.9073913,60 32.6086957,60 L32.6086957,60 Z M39.1278261,19.5652174 C36.2530435,19.5652174 33.9130435,21.9052174 33.9130435,24.7813043 L33.9130435,55.9108696 L41.1652174,49.8665217 C41.3986957,49.6721739 41.6947826,49.5652174 42,49.5652174 L70.436087,49.5652174 C73.3121739,49.5652174 75.6521739,47.2252174 75.6521739,44.3491304 L75.6521739,24.7813043 C75.6521739,21.9052174 73.3121739,19.5652174 70.436087,19.5652174 L39.1278261,19.5652174 L39.1278261,19.5652174 Z"
                      id="Fill-976"
                    ></path>
                    <path
                      d="M41.7391304,39.1304348 C39.5817391,39.1304348 37.826087,37.3747826 37.826087,35.2173913 C37.826087,33.06 39.5817391,31.3043478 41.7391304,31.3043478 C43.8965217,31.3043478 45.6521739,33.06 45.6521739,35.2173913 C45.6521739,37.3747826 43.8965217,39.1304348 41.7391304,39.1304348 L41.7391304,39.1304348 Z M41.7391304,33.9130435 C41.0191304,33.9130435 40.4347826,34.4973913 40.4347826,35.2173913 C40.4347826,35.9373913 41.0191304,36.5217391 41.7391304,36.5217391 C42.4591304,36.5217391 43.0434783,35.9373913 43.0434783,35.2173913 C43.0434783,34.4973913 42.4591304,33.9130435 41.7391304,33.9130435 L41.7391304,33.9130435 Z"
                      id="Fill-977"
                    ></path>
                    <path
                      d="M54.7826087,39.1304348 C52.6252174,39.1304348 50.8695652,37.3747826 50.8695652,35.2173913 C50.8695652,33.06 52.6252174,31.3043478 54.7826087,31.3043478 C56.94,31.3043478 58.6956522,33.06 58.6956522,35.2173913 C58.6956522,37.3747826 56.94,39.1304348 54.7826087,39.1304348 L54.7826087,39.1304348 Z M54.7826087,33.9130435 C54.0626087,33.9130435 53.4782609,34.4973913 53.4782609,35.2173913 C53.4782609,35.9373913 54.0626087,36.5217391 54.7826087,36.5217391 C55.5026087,36.5217391 56.0869565,35.9373913 56.0869565,35.2173913 C56.0869565,34.4973913 55.5026087,33.9130435 54.7826087,33.9130435 L54.7826087,33.9130435 Z"
                      id="Fill-978"
                    ></path>
                    <path
                      d="M67.826087,39.1304348 C65.6686957,39.1304348 63.9130435,37.3747826 63.9130435,35.2173913 C63.9130435,33.06 65.6686957,31.3043478 67.826087,31.3043478 C69.9834783,31.3043478 71.7391304,33.06 71.7391304,35.2173913 C71.7391304,37.3747826 69.9834783,39.1304348 67.826087,39.1304348 L67.826087,39.1304348 Z M67.826087,33.9130435 C67.106087,33.9130435 66.5217391,34.4973913 66.5217391,35.2173913 C66.5217391,35.9373913 67.106087,36.5217391 67.826087,36.5217391 C68.546087,36.5217391 69.1304348,35.9373913 69.1304348,35.2173913 C69.1304348,34.4973913 68.546087,33.9130435 67.826087,33.9130435 L67.826087,33.9130435 Z"
                      id="Fill-979"
                    ></path>
                    <path
                      d="M10.4347826,22.173913 C8.2773913,22.173913 6.52173913,20.4182609 6.52173913,18.2608696 C6.52173913,16.1034783 8.2773913,14.3478261 10.4347826,14.3478261 C12.5921739,14.3478261 14.3478261,16.1034783 14.3478261,18.2608696 C14.3478261,20.4182609 12.5921739,22.173913 10.4347826,22.173913 L10.4347826,22.173913 Z M10.4347826,16.9565217 C9.71478261,16.9565217 9.13043478,17.5408696 9.13043478,18.2608696 C9.13043478,18.9808696 9.71478261,19.5652174 10.4347826,19.5652174 C11.1547826,19.5652174 11.7391304,18.9808696 11.7391304,18.2608696 C11.7391304,17.5408696 11.1547826,16.9565217 10.4347826,16.9565217 L10.4347826,16.9565217 Z"
                      id="Fill-980"
                    ></path>
                    <path
                      d="M23.4782609,22.173913 C21.3208696,22.173913 19.5652174,20.4182609 19.5652174,18.2608696 C19.5652174,16.1034783 21.3208696,14.3478261 23.4782609,14.3478261 C25.6356522,14.3478261 27.3913043,16.1034783 27.3913043,18.2608696 C27.3913043,20.4182609 25.6356522,22.173913 23.4782609,22.173913 L23.4782609,22.173913 Z M23.4782609,16.9565217 C22.7582609,16.9565217 22.173913,17.5408696 22.173913,18.2608696 C22.173913,18.9808696 22.7582609,19.5652174 23.4782609,19.5652174 C24.1982609,19.5652174 24.7826087,18.9808696 24.7826087,18.2608696 C24.7826087,17.5408696 24.1982609,16.9565217 23.4782609,16.9565217 L23.4782609,16.9565217 Z"
                      id="Fill-981"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a> */}
        <button class="scrollToTopBtn">
          <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
            />
          </svg>
        </button>
      </footer>
    </>
  );
};

export default Footer;
