import React, { useState, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Chevron from './Chevron';
import CustomLink from '../Link';

function Accordion(props) {
  const [setActive, setActiveState] = useState('');
  const content = useRef(null);

  const { accordionContent } = props;
  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'accordion--open' : '');
  };

  return (
    <div key={accordionContent.title} className={`accordion ${setActive}`} onClick={toggleAccordion}>
      <div className="accordion__header">
        {accordionContent.title}
        <Chevron width={16} fill={'#777'} className={'accordion__icon'} />
      </div>

      {accordionContent.accordionEntries.map((entries) => {
        return (
          <div className="accordion__content">
            <CustomLink link={entries} />
          </div>
        );
      })}
    </div>
  );
}

export default Accordion;
